.box {
    /* if not using border-box
    width: 218px;
    */
    width: 240px;
    background: #ffffff;
    border: 1px solid #999;
    padding: 15px;
    margin: 10px;
    float: left;

    -webkit-border-radius: 5px;
    border-radius: 5px;

    -webkit-box-shadow: 0px 0px 5px 1px #aaa;
    box-shadow: 0px 0px 5px 1px #aaa;
}
.isotope-item {
    z-index: 2;
}

.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1;
}

.isotope,
.isotope .isotope-item {
    /* change duration value to whatever you like */
    -webkit-transition-duration: 0.8s;
    -moz-transition-duration: 0.8s;
    transition-duration: 0.8s;
}

.isotope {
    -webkit-transition-property: height, width;
    -moz-transition-property: height, width;
    transition-property: height, width;
}

.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -moz-transition-property:    -moz-transform, opacity;
    transition-property:         transform, opacity;
}